import * as React from "react";
import Hero from "./hero";
import CardRadio from "./card_radio";
import FlavorSelect from "./flavor_select";
function SubscriptionPlan() {
  return (
    <div className="">
      <Hero />
      <CardRadio />
      <FlavorSelect />
    </div>
  );
}
export default SubscriptionPlan;
