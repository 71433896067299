import * as React from "react";
import Home from "./main_page/home";
import OrderConfirmation from "./order_confirmation/order_confirmation";
import SubscriptionPlan from "./subscription_plan/subscription_plan";
import Faq from "./faq/faq";
import Atheletes from "./contact/atheletes/atheletes";
import Footer from "./footer/footer";
import Header from "./header/header";
import ProductPage from "./product_page/product_page";
import Checkout from "./checkout/checkout";
import Contact from "./contact/contact";
import { Routes, Route } from "react-router-dom";
import About from "./about/about";
import Subscribe from "./subscribe/subscribe";
import ProductCategories from "./product_categories/product_categories";
import ScrollToTop from "./utils/scroll_to_top";
import Banner from "./banner/banner";
import Popup from "./popups/main_popup";
/*
 * Official Big blue colors
 * Blue - 004185
 * Black - 211F1F
 * White - FFFFFF
 * */
export default function App() {
  return (
    <div>
      <ScrollToTop />
      <Header />
      <Banner />
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="/product/:id" element={<ProductPage />} />
          <Route path="/subscription_plan/:id" element={<SubscriptionPlan />} />
          <Route path="home" element={<Home />} />
          <Route path="faq" element={<Faq />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="contact" element={<Contact />} />
          <Route path="categories" element={<ProductCategories />} />
          <Route path="subscribe" element={<Subscribe />} />
          <Route path="atheletes" element={<Atheletes />} />
          <Route path="order_confirmation" element={<OrderConfirmation />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
}
