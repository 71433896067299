import { Tab } from "@headlessui/react";
import { fetch_image } from "../api";
import * as React from "react";
import cn from "../utils/cn";
function ImageGallery(props) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  React.useEffect(() => {
    setSelectedIndex(0);
  }, [props.selected]);
  return (
    <Tab.Group
      selectedIndex={selectedIndex}
      onChange={setSelectedIndex}
      as="div"
      className="flex flex-col-reverse"
    >
      <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
        <Tab.List className="grid grid-cols-4 gap-6">
          {[
            ...(props.selected?.images ? [...props.selected?.images] : []),
            ...props.defaultImages,
          ].map((image, idx) => (
            <Tab
              key={`image-tab-${idx}`}
              className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
            >
              {({ selected }) => (
                <>
                  <span className="absolute inset-0 overflow-hidden rounded-md">
                    <img
                      src={image.image_id ? fetch_image(image.image_id) : image}
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    className={cn(
                      selected ? "ring-indigo-500" : "ring-transparent",
                      "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </Tab>
          ))}
        </Tab.List>
      </div>

      <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
        {[
          ...(props.selected?.images
            ? [...props.selected?.images]
            : [props.defaultImage]),
          ...props.defaultImages,
        ].map((image, idx) => (
          <Tab.Panel key={`image-tab-${idx}`}>
            <img
              src={image.image_id ? fetch_image(image.image_id) : image}
              alt="default "
              className="h-full w-full object-cover object-center sm:rounded-lg"
            />
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}
export default ImageGallery;
