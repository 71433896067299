import React from "react";
import cn from "../utils/cn";

const tabs = [
  {
    name: "Drink Information",
    title: "Description",
    content:
      "Big Blue Energy is a unique, exciting brand redefining the energy and electrolyte drink experience. We offer a variety of energy boosting drinks, as well as low-caffeine options and caffeine-free electrolyte blends. True to our brand, all Big Blue Energy drinks are non-carbonated, ensuring a smooth, satisfying taste packed with bold flavors. With a commitment to innovation, Big Blue Energy is here to offer better tasting and higher quality drink formulations. ",
    list: [
      "CAFFEINE: Energy 220-240mg -- LYTE 100mg -- Reload 0mg",
      "SUGAR: All drinks available at Big Blue contain ZERO Sugar",
      "ELECTROLYTES: Reloads contain 1000mg Electrolyte Blend (see Nutrition Facts)",
      "Each packet makes a 32oz drink (see How to Make)",
      "Orders include cup sets - Optional (Max 8 per order)",
    ],
  },
  {
    name: "How to Make",
    title: "Making Your Big Blue Drink",
    content:
      "What makes Big Blue so special is that every single drink is idiviually packed in its own hand held packet. This allows you to take your drink virtually anywhere and make it whenever you want! On top of that, making your drink is easy as can be. There are 1000 different ways to make your Big Blue, but here are a few most popular.",
    list: [
      "The Big Blue Way... 1.) Add your packet to your 32oz cup set 2.) Dissolve the powder with a little bit of warm water 3.) Fill your cup with ice 4.) Top off with cold water, lid and mix it up",
      "Add your packet to a 20-24oz shaker bottle and shake up until fully dissolved. *Beware of preassure build up*",
      "Grab any 20-64oz tumbler and try it out. Some people like a stronger drink while others want a calmer taste. Add your packet to any amount if water, with or without ice, and see what you like best. Just make sure your drink is well mixed so you get a consistent flavor all the way through.",
    ],
  },
  {
    name: "Nutrition Facts",
    title: "Nutrition and Ingredients",
    details: [
      "Big Blue Energy and LYTE drinks contain approx: 30 CALORIES, 350mg SODIUM, 7g CARBS, 0g SUGAR. Big Blue Reload drinks contain approx: 20 CALORIES, 600mg SODIUM, 4g CARBS, 0g SUGAR.",
      "Big Blue Reload ELECTROLYTE BLEND: 340mg SODIUM, 200mg MAGNESIUM, 600mg POTASSIUM, 1000mg VIT C, 10mg ZINC, VIT D, VIT B3, VIT B6, VIT B12. ",
      "Complete ingredient list for all Big Blue Drinks. Each ingredient IS NOT included in every drink, but a collection of all ingredients throughout all formulations. Please contact us for questions on specific drink ingredient lists. Ground Black Tea, Ground Green Tea, Citric Acid, Taurine, Sodium Citrate, Malic Acid, Caffeine, Natural and Artificial Fruit Flavors, Sucralose, Potassium Phosphate, Glucuronolactone, Fruit Juice Solids, Sodium Bicarbonate, Maltodextrin, Potassium Citrate, Stevia, Modified Food Starch, Magnesium Oxide, Magnesium Malate, Aspartame, Silicon Dioxide, Gum Acacia, Xanthan Gum. Drinks include a small percent of the following: Niacinamide (B-Vitamin), Zinc Oxide, D-Calcium Pantothenate (B-Vitamin), Cyanocobalamin & Methylcobalamin (B-Vitamin), Vitamin D, Folic Acid, Biotin (B-Vitamin), Pyridoxine HCL (B-Vitamin), Acesulfame K, Calcium Silicate, Monocalcium Phosphate, Salt, Tricalcium Phosphate, Ascorbic Acid (Vitamin C), Dicalcium Phosphate, Corn Syrup Solids, Vitamin A & E Acetate, Beta Carotene, Artificial Colors, Blue 1, Red 40, Titanium Dioxide, Yellow 5, Yellow 6, Caramel Color.",
    ],
  },
];

function InfoTabs() {
  const [state, setState] = React.useState(tabs[0].name);
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          defaultValue={tabs[0].id}
          onChange={(e) => {
            console.log(e);
            setState(e.target.value);
          }}
          className="block w-full rounded-md border-gray-200 py-2 mt-4 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        >
          {tabs.map((tab, idx) => (
            <option key={`tab-id-${idx}`}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b w-fit border-gray-200">
          <nav aria-label="Tabs" className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => setState(tab.name)}
                aria-current={tab.id === state ? "page" : undefined}
                className={cn(
                  tab.name === state
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 px-1 py-4 text-sm mt-4 font-medium"
                )}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      {tabs
        .filter((x) => x.name === state)
        .map((tab, idx) => (
          <div key={`tab-${idx}`} className="p-6">
            <div className="text-2xl font-bold">{tab.title}</div>
            <div className="text-sm mt-3">{tab.content}</div>
            {tab.list && (
              <ul className="list-disc text-sm space-y-1 ms-6 mt-3">
                {tab.list.map((text, idx) => (
                  <li key={`tabs-list-${idx}`}>{text}</li>
                ))}
              </ul>
            )}
            {tab.details && (
              <div>
                {tab.details.map((detail, idx) => (
                  <div key={`detail-${idx}`} className="mt-2 text-sm">
                    {detail}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
    </div>
  );
}
export default InfoTabs;
