import img1 from "../resource/newfotos/foto5.png";
import img2 from "../resource/newfotos/foto4.png";
import img3 from "../resource/newfotos/foto6.png";
const posts = [
  {
    title: "Stop Overspending",
    href: "#",
    category: { name: "Cost", href: "#" },
    description:
      "At only $3.65 per 32oz drink, Big Blue is one of the most cost effective drink products on the market. Stop spending $6-10 on a below average drink and buy something you can enjoy, without breaking the bank.",
    date: "Jan 18, 1999",
    datetime: "01-18-1999",
    imageUrl: img1,
    readingTime: "Now",
  },
  {
    title: "A Taste you Enjoy",
    href: "#",
    category: { name: "Flavor", href: "#" },
    description:
      "Tired of those syrupy carbonated canned energy drinks? Or the coffee shop drinks that make you feel gross? Big Blue offers a refreshing flavor without the nasty after taste. Finally, a product that doesnt taste fake.",
    date: "Sep 2, 1999",
    datetime: "09-02-1999",
    imageUrl: img2,
    readingTime: "Forever",
  },
  {
    title: "Quit with the Crashing and Jitters",
    href: "#",
    category: { name: "Energy", href: "#" },
    description:
      "Whether you need lots of energy or none at all, Big Blue has a drink for you. Quit crashing and feeling jittery when you need a caffeine boost. All of our drinks are sugar free and offer a more stable, long lasting energy.",
    date: "May 4, 2001",
    datetime: "05-04-2001",
    imageUrl: img3,
    readingTime: "47 years ago",
  },
];

function WhyBigBlue() {
  return (
    <div className="relative bg-gradient-to-b from-white via-purple-100 to-white px-6 pb-20 pt-10 lg:px-8 lg:pb-28 lg:pt-8">
      <div className="relative mx-auto max-w-7xl">
        <div className="text-center">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            The BIG BLUE Difference
          </h2>
          <p className="mx-auto mt-3 max-w-2xl text-lg text-gray-500 sm:mt-4">
            Don't let the big brands fool you. We actually care about YOU!
          </p>
        </div>
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <div
              key={post.title}
              className="flex flex-col overflow-hidden rounded-lg shadow-lg"
            >
              <div className="shrink-0">
                <img
                  alt=""
                  src={post.imageUrl}
                  className="h-48 w-full object-cover"
                />
              </div>
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                  <div className="text-sm font-medium text-indigo-600">
                    <div className="">{post.category.name}</div>
                  </div>
                  <div className="mt-2 block">
                    <p className="text-xl font-semibold text-gray-900">
                      {post.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {post.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default WhyBigBlue;
