import { CheckIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import cn from "../utils/cn";

function Tiers() {
  const navigate = useNavigate();
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">
            Pricing
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Pricing plans custom to you
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          We have a plan that works for all levels of customer needs!
        </p>
        <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier, tierIdx) => (
            <div
              key={tier.id}
              className={cn(
                tier.mostPopular ? "lg:z-10 lg:rounded-b-none" : "lg:mt-8",
                tierIdx === 0 ? "lg:rounded-r-none" : "",
                tierIdx === tiers.length - 1 ? "lg:rounded-l-none" : "",
                "flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10"
              )}
            >
              <div>
                <div className="flex items-center justify-between gap-x-4">
                  <h3
                    id={tier.id}
                    className={cn(
                      tier.mostPopular ? "text-indigo-600" : "text-gray-900",
                      "text-lg font-semibold leading-8"
                    )}
                  >
                    {tier.name}
                  </h3>
                  {tier.mostPopular ? (
                    <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                      Best Deal
                    </p>
                  ) : null}
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-600">
                  {tier.description}
                </p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    {tier.priceMonthly}
                  </span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">
                    /drink
                  </span>
                </p>
                <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <button
                // onClick={() => navigate(tier.href)}
                aria-describedby={tier.id}
                className={cn(
                  tier.mostPopular
                    ? "bg-indigo-600 text-white shadow-sm hover:bg-indigo-500"
                    : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                  "mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                )}
              >
                Almost Here!
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const tiers = [
  {
    name: "The Casual",
    id: "tier-pussy",
    href: "/subscription_plan/1",
    priceMonthly: "$3.65",
    description: "For those that enjoy Big Blue Energy",
    features: ["15 drinks", "Choose any drink combo", "+$5 shipping"],
    mostPopular: false,
  },
  {
    name: "All-In",
    id: "tier-proletariat",
    href: "/subscription_plan/2",
    priceMonthly: "$3.29",
    description: "No such thing as too much Big Blue",
    features: [
      "60 drinks",
      "Save 10%",
      "Choose any drink combo",
      "Free shipping",
      "FREE - Monthly Mystery Flavor",
    ],
    mostPopular: true,
  },
  {
    name: "The Connissuer",
    id: "tier-cardiac-arrest",
    href: "/subscription_plan/3",
    priceMonthly: "$3.47",
    description: "One for every day of the month",
    features: [
      "30 drinks",
      "Save 5%",
      "Choose any drink combo",
      "Free shipping",
    ],
    mostPopular: false,
  },
];

export default Tiers;
