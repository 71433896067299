import React from "react";
import TopNav from "./top_nav";
import { FloatingNav } from "../components/ui/floating-navbar";
import { Home, ShoppingCart, ShoppingBag } from "tabler-icons-react";
function Header() {
  const navItems = [
    {
      name: "Home",
      link: "/",
      icon: <Home className="h-4 w-4 text-neutral-500 dark:text-white" />,
    },
    {
      name: "Shop",
      link: "/categories",
      icon: <ShoppingBag />,
    },
    {
      name: "Checkout",
      link: "/checkout",
      icon: (
        <ShoppingCart className="h-4 w-4 text-neutral-500 dark:text-white" />
      ),
    },
  ];
  return (
    <div className="relative  w-full z-20">
      <FloatingNav navItems={navItems} />
      <TopNav />
    </div>
  );
}

export default Header;
