import { XMarkIcon } from "@heroicons/react/20/solid";
import React from "react";

function Banner() {
  const [state, setState] = React.useState(true);
  if (!state) return;
  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gradient-to-r from-purple-200 to-pink-200 px-6 py-2.5 sm:px-3.5 before:flex-1">
      <div
        aria-hidden="true"
        className="absolute left-[max(-1rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-xl"
      >
        <div
          style={{
            clipPath:
              "polygon(74.8% 41.9%, 97.2% 73.9%, 100% 34.9%, 92.5% 50%, 87.5% 50%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
          }}
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-80"
        />
      </div>
      <div
        aria-hidden="true"
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
      >
        <div
          style={{
            clipPath:
              "polygon(30% 41.9%, 97.2% 73.2%, 100% 35%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50% 56.8%, 46.9% 44%, 48.3% 17.4%, 50% 53%, 0% 27.9%, 11.9% 74.2%, 25% 54.1%, 68.6% 100%, 75% 41.9%)",
          }}
          className="aspect-[577/310] w-[35rem] -translate-x-50 bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-80"
        />
      </div>
      <div className="text-[12px] md:text-base lg:text-base text-black">
        <a href="categories" className="">
          <strong className="font-bold">FREE SHIPPING</strong>
          <svg
            viewBox="0 0 2 2"
            aria-hidden="true"
            className="mx-1 inline h-0 w-0 fill-current"
          >
            <circle r={1} cx={1} cy={1} />
          </svg>
          <span className="inline-block">
            Spend $100 and recieve FREE shipping
          </span>
          <span className="hidden sm:inline-block ps-1">
            anywhere in the U.S.
          </span>
        </a>
      </div>
      <div className="flex flex-1 justify-end">
        <button
          onClick={() => setState(false)}
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon aria-hidden="true" className="h-5 w-5 text-black" />
        </button>
      </div>
    </div>
  );
}
export default Banner;
