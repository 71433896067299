import * as React from "react";
import Toaster from "../common/toaster";
import Loading from "../common/loading";
import { HeartIcon, PlusIcon, MinusIcon } from "@heroicons/react/24/outline";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { add_to_cart } from "../api";
import cn from "../utils/cn";
/*
 * props {
 *  selected: Object
 * }
 * */
function AddToBag(props) {
  const [count, setCount] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const submitMutation = useMutation({
    mutationFn: async (item) => {
      setLoading(true);
      await add_to_cart(item);
      setCount(1);
    },
    onError: () => {
      setShowError(true);
    },
    onSuccess: () => {
      setShowSuccess(true);
      // This doesn't reset the radio like I would like
      // so not doing it at the moment
      // if (props.setSelected) {
      //   props.setSelected(null);
      // }
    },
    onSettled: () => {
      setLoading(false);
    },
  });
  const handleSubmit = () => {
    submitMutation.mutate({
      item_id: props.selected.item_id,
      item_count: count,
    });
  };
  const count_change = (e, c) => {
    if (c && +count + c > 0) {
      setCount(+count + c);
    } else if (e.target.value > 0) {
      setCount(e.target.value);
    }
  };
  return (
    <div className="mt-10 flex relative">
      <Toaster
        show={showError}
        setShow={setShowError}
        type="error"
        text="Error adding to cart"
      />
      <Toaster
        show={showSuccess}
        setShow={setShowSuccess}
        type="success"
        text="Successfully added to cart"
      />
      <Loading loading={loading} />
      <button
        onClick={() => handleSubmit(props.selected)}
        type="button"
        disabled={!props.selected}
        className={cn(
          "flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent px-8 py-3 text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full ",
          props.selected ? "bg-indigo-600 hover:bg-indigo-400" : "bg-slate-400"
        )}
      >
        Add To Cart
      </button>
      <div className="relative flex items-center max-w-[8rem] ml-4">
        <button
          type="button"
          onClick={(e) => count_change(e, -1)}
          className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-2 h-full focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
        >
          <MinusIcon className="w-5 h-5" />
        </button>
        <input
          type="text"
          id="quantity-input"
          value={count}
          disabled
          className="bg-gray-50 border-x-0 border-gray-300 h-full text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          required
        />
        <button
          type="button"
          onClick={(e) => count_change(e, 1)}
          className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-2 h-full focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
        >
          <PlusIcon className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
}

export default AddToBag;
